import { PUSH_HISTORY, POP_HISTORY } from './constants';

const PathHistory = (state = [], action) => {
    switch (action.type) {
        case PUSH_HISTORY:
            return [ ...state, action.payload ]
        case POP_HISTORY:
            return state.pop(); 
        default:
            return state 
    }
}

export default PathHistory;