import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

function _extends(_extends) { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var CreditCard = forwardRef(function (_ref, ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'currentColor' : _ref$color,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 24 : _ref$size,
      rest = _objectWithoutProperties(_ref, ["color", "size"]);

  return React.createElement("svg", _extends({
    ref: ref,
    xmlns: "http://www.w3.org/2000/svg",
    width: size,
    height: size,
    viewBox: "0 0 26 26",
    fill: "none",
    stroke: color,
    strokeWidth: "0.1",
  }, rest), React.createElement("path", {
    d: "M21.6666 9.74984V7.58317H4.33329V9.74984H21.6666ZM21.6666 12.9998H4.33329V18.4165H21.6666V12.9998ZM4.33329 5.4165H21.6666C22.2413 5.4165 22.7924 5.64478 23.1987 6.05111C23.605 6.45743 23.8333 7.00853 23.8333 7.58317V18.4165C23.8333 18.9911 23.605 19.5422 23.1987 19.9486C22.7924 20.3549 22.2413 20.5832 21.6666 20.5832H4.33329C3.75866 20.5832 3.20756 20.3549 2.80123 19.9486C2.3949 19.5422 2.16663 18.9911 2.16663 18.4165V7.58317C2.16663 7.00853 2.3949 6.45743 2.80123 6.05111C3.20756 5.64478 3.75866 5.4165 4.33329 5.4165V5.4165ZM6.49996 15.1665H7.58329C7.87061 15.1665 8.14616 15.2806 8.34933 15.4838C8.55249 15.687 8.66663 15.9625 8.66663 16.2498C8.66663 16.5372 8.55249 16.8127 8.34933 17.0159C8.14616 17.219 7.87061 17.3332 7.58329 17.3332H6.49996C6.21264 17.3332 5.93709 17.219 5.73393 17.0159C5.53076 16.8127 5.41663 16.5372 5.41663 16.2498C5.41663 15.9625 5.53076 15.687 5.73393 15.4838C5.93709 15.2806 6.21264 15.1665 6.49996 15.1665ZM11.9166 15.1665H17.3333C17.6206 15.1665 17.8962 15.2806 18.0993 15.4838C18.3025 15.687 18.4166 15.9625 18.4166 16.2498C18.4166 16.5372 18.3025 16.8127 18.0993 17.0159C17.8962 17.219 17.6206 17.3332 17.3333 17.3332H11.9166C11.6293 17.3332 11.3538 17.219 11.1506 17.0159C10.9474 16.8127 10.8333 16.5372 10.8333 16.2498C10.8333 15.9625 10.9474 15.687 11.1506 15.4838C11.3538 15.2806 11.6293 15.1665 11.9166 15.1665Z",
    fill: color
  }));
});
CreditCard.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
CreditCard.displayName = 'CreditCard';
export default CreditCard;

