import React, { Component } from 'react';
import Routes from './routes/Routes';

// setup fake backend

//Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Themes

// default
import 'react-tagsinput/react-tagsinput.css';
import './assets/scss/theme.scss';

// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';


// configure fake backend
//configureFakeBackend();

/**
 * Main app component
 */
class App extends Component {

  render() {
    
    return ( 
            <>
            <ToastContainer 
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
              <Routes/>
            </>
          
          );
  }
}

export default App;
