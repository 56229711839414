//Url Base Api
export const URL_API = process.env.REACT_APP_API_URL;

//POST - Get Token of user (email, password)
export const LOGIN_API = URL_API + 'api/v1/auth/login/';

//GET - Get User (token)
//Patch - Update User (token)
export const TOKEN_API = URL_API + 'api/v1/auth/user/';

//GET - Get Categories
export const CATEGORIES_API = URL_API + 'api/v1/categories/';

//GET - Get Languages
export const LANGUAGES_API = URL_API + 'api/v1/languages/';

//GET - Get Events
//POST - Insert Event
export const EVENT_API = URL_API + 'api/v1/events/';

//GET - Get organized events
export const ORGANIZED_EVENT_API = URL_API + 'api/v1/events/organized_events/';

//Get users on event
export const USERS_ON_MY_EVENTS_API = URL_API + 'api/v1/events/users_who_attended_my_events';

//Get VIP users on event
export const VIP_USERS_ON_MY_EVENTS_API = URL_API + 'api/v1/vip/membership/';

//Get events :id
export const EVENTS_USER_API = URL_API + 'api/v1/events/attended/';

//DELETE membership :id
export const USER_MEMBERSHIP = URL_API + 'api/v1/memberships/';

//Add user to event
export const EVENT_ADD_USER_API = URL_API + 'api/v1/events/add_own_attendees';

//GET - Get Organizations
//POST - Insert Organization
export const ORGANIZATION_API = URL_API + 'api/v1/organizations/';

//POST - Devices Api
export const DEVICE_API = URL_API + 'api/v1/devices-fcm/';

//POST, PUT, GET
export const PROMO_API = URL_API + 'api/v1/discount_coupons/';
