// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON, fetchPromiseJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD } from './constants';

import { LANGUAGES_API, LOGIN_API, TOKEN_API } from '../../constants/api';


import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from './actions';


/**
 * Sets the session
 * @param {*} user
 */
export const setSession = user => {
    let cookies = new Cookies();
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};

/**
 * Creates cookies if value is passed
 * else removes cookies
 * @param {string} name 
 * @param {*} value 
 */
export const setCookie = (name, value) => {
    const cookies = new Cookies();
    if (value) cookies.set(name, JSON.stringify(value));
    else cookies.remove(name);
}

export const getCookie = name => {
    const cookies = new Cookies();
    const data = cookies.get(name);
    return data ? (typeof data == 'object' ? data : JSON.parse(data)) : null;
}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password } }) {
    //mixpanel.init("f80e866a95716d3ff754745704264d57", { "api_host": "https://api-eu.mixpanel.com" }, "");
    const options = {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: { 
                    'Content-Type': 'application/json',
                },
    };

    try {
        const response = yield call(fetchJSON, LOGIN_API, options);
        const token = response.key;

        if (!response.hasOwnProperty("key")) {
            let error = response;
            throw error;
        }

        /*
        mixpanel.identify(email);
        mixpanel.track("Login to Administration", {"time of login": d.toLocaleString()})
        */

        const optionss = {
            method: 'GET',
            headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Token '+ token
                    },
        };
        try {
            const responses = yield call(fetchJSON, TOKEN_API, optionss);
            responses.role = "Admin";
            responses.token = token;
            setSession(responses);
            fetchPromiseJSON(LANGUAGES_API, optionss)
                .then(data => {
                    const cookie = new Cookies();
                    cookie.set("languages", data.results)
                });
            yield put(loginUserSuccess(responses));
           
        } catch (errors) {
            console.log(errors)
        }




    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        let ke = Object.keys(message);
        yield put(loginUserFailed(message[ke][0]));
        setSession(null);
    }

    
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
