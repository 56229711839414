import ArrowCircle from './ArrowCircle';
import Bell from './Bell';
import Bookmark from './Bookmark';
import CreditCard from './CreditCard';
import ChevronRight from './ChevronRight';
import Inbox from './Inbox';
import LogOut from './LogOut';
import NewSettings from './NewSettings';
import PieChart from './PieChart';
import PlusCircle from './PlusCircle';
import Profile from './Profile';
import Settings from './Settings';
import Support from './Support';
import VipInbox from './VipInbox';

const customIcons = {
    ArrowCircle,
    Bell,
    Bookmark,
    CreditCard,
    ChevronRight,
    Inbox,
    LogOut,
    NewSettings,
    PieChart,
    PlusCircle,
    Profile,
    Settings,
    Support,
    VipInbox,
};

export default customIcons;
