import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import Icons from '../assets/icons';
import { isUserAuthenticated, isInOrganisation } from '../helpers/authUtils';
import 'bootstrap/dist/css/bootstrap.min.css';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const Benefit = React.lazy(() => import('../pages/benefit'));
const Events = React.lazy(() => import('../pages/events'));
const ProfilePage = React.lazy(() => import('../pages/profile'));
const UpdateProfilePage = React.lazy(() => import('../pages/profile/Update'));
const ProfileSettings = React.lazy(() => import('../pages/profile/Settings'));
const EventDetail = React.lazy(() => import('../pages/events/detail'));
const Address = React.lazy(() => import('../pages/address'));
const AddressVip = React.lazy(() => import('../pages/address_vip'));
const AddressVipCreate = React.lazy(() => import('../pages/address_vip/create'));
const AddressDetail = React.lazy(() => import('../pages/address/detail'));
const Promo = React.lazy(() => import('../pages/events/promo'));

// pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// dynamic pages
const ProPopUp = React.lazy(() => import('../components/Pro/ProPopUp'));
const ProForm = React.lazy(() => import('../components/Pro/ProForm'));
const CreateEventContent = React.lazy(() => import('../components/Events/createEvent/CreateEventContent'));

// TODO create component pages and route them
// TODO divide routes for pro user and regular user
// TODO update roles
// TODO non pro routes shows finance and clinets with star icon and shows popup to PRO

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    document.body.classList.remove('modal-open'),
    (
        <>
            <Route
                {...rest}
                render={(props) => {
                    if (!isUserAuthenticated()) {
                        // not logged in so redirect to login page with the return url
                        return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                    }

                    // authorised so return component
                    return <Component {...props} />;
                }}
            />
        </>
    )
);
// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// pages
const pagesRoutes = {
    path: '/pages',
    name: 'Pages',
    header: 'Custom',
    icon: FeatherIcon.FileText,
    children: [
        {
            path: '/pages/starter',
            name: 'Starter',
            component: Starter,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/pages/profile',
            name: 'Profile',
            component: Profile,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/pages/activity',
            name: 'Activity',
            component: Activity,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/pages/invoice',
            name: 'Invoice',
            component: Invoice,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/pages/pricing',
            name: 'Pricing',
            component: Pricing,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/pages/error-404',
            name: 'Error 404',
            component: Error404,
            route: Route,
        },
        {
            path: '/pages/error-500',
            name: 'Error 500',
            component: Error500,
            route: Route,
        },
    ],
};

// TODO make hidden property and use it in render
const profileRoutes = {
    path: '/profile',
    name: 'Profil',
    special: 'hidden',
    children: [
        {
            path: '/profile',
            name: 'Profil',
            component: ProfilePage,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/update',
            name: 'Profil',
            component: UpdateProfilePage,
            route: PrivateRoute,
            roles: ['Admin'],
            special: 'hidden',
        },
        {
            path: '/settings',
            name: 'Nastavení',
            component: ProfileSettings,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/account/logout',
            name: 'Odhlásit se',
            component: Logout,
            route: Route,
        },
    ],
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Domov',
    icon: Icons.PieChart,
    //header: 'Navigation',
    component: Dashboard,
    roles: ['Admin'],
    route: PrivateRoute,
};

const eventRoutes = {
    path: '/events',
    name: 'Události',
    icon: Icons.Bookmark,
    component: Events,
    roles: ['Admin'],
    route: PrivateRoute,
};

const eventRoutesDetail = {
    path: '/event/:id',
    component: EventDetail,
    route: PrivateRoute,
};

const clientRoutes = {
    path: '/clients',
    name: 'Adresář klientů',
    icon: Icons.Inbox,
    component: Address,
    roles: ['Admin'],
    route: PrivateRoute,
};

const vipClientRoutes = {
    path: '/vip_clients',
    name: 'VIP členství',
    icon: Icons.VipInbox,
    component: AddressVip,
    roles: ['Admin'],
    route: PrivateRoute,
};

const clientDetailRoutes = {
    path: '/client/:id',
    name: 'Aresář klientů',
    icon: Icons.Inbox,
    component: AddressDetail,
    roles: ['Admin'],
    route: PrivateRoute,
};

/*const createActionRoutes = {
    path: '/events/create',
    name: 'Vytvořit akci',
    icon: Icons.PlusCircle,
    component: Dashboard,
    roles: ['Admin'],
    route: PrivateRoute,
    special: 'button',
};*/

const supportRoutes = {
    path: '/benefit',
    name: 'Podpora',
    icon: Icons.Support,
    component: Benefit,
    roles: ['Admin'],
    route: PrivateRoute,
    position: 'bottom',
};

const settingsRoutes = {
    path: '/settings',
    component: ProfileSettings,
    name: 'Nastavení',
    icon: Icons.NewSettings,
    roles: ['Admin'],
    route: PrivateRoute,
    position: 'bottom',
};

const PromoRoutes = {
    path: '/kod/:id',
    component: Promo,
    route: PrivateRoute,
};

/*const upgradeFormRoutes = {
    path: '/onboarding',
    component: ProForm,
    roles: ['Admin'],
    route: PrivateRoute,
};*/

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const dynamicPaths = [
    {
        pathSuffix: 'pro',
        component: ProPopUp,
    },
    {
        pathSuffix: 'pro_form',
        component: ProForm,
    },
    {
        pathSuffix: 'create_event',
        component: CreateEventContent,
    },
    {
        pathSuffix: 'create',
        component: AddressVipCreate,
    },
];

const isUserInOrganisation = isInOrganisation();

// All routes
const allRoutes = [
    clientDetailRoutes,
    rootRoute,
    dashboardRoutes,
    pagesRoutes,
    eventRoutes,
    clientRoutes,
    ...(isUserInOrganisation ? [vipClientRoutes] : []),
    //createVipClientRoute,
    //createActionRoutes,
    //supportRoutes,
    settingsRoutes,
    authRoutes,
    profileRoutes,
    //upgradeFormRoutes,
    eventRoutesDetail,
    PromoRoutes,
];

const authProtectedRoutes = [
    dashboardRoutes,
    eventRoutes,
    clientRoutes,
    ...(isUserInOrganisation ? [vipClientRoutes] : []),
    //createActionRoutes,
    //supportRoutes,
    settingsRoutes,
    profileRoutes,
    //upgradeFormRoutes,
    //eventRoutesDetail,
];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes, dynamicPaths };
