// @flow
import { Cookies } from 'react-cookie';

import { fetchJSON } from './api';
import { URL_API } from '../constants/api';
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + user.token,
        },
    };

    const response = fetchJSON(`${URL_API}api/v1/auth/user/`, options);

    return response.then((result) => {
        if (typeof result == 'object') {
            return true;
        } else {
            return false;
        }
    });
};

const isRolePro = () => {
    const user = getLoggedInUser();
    try {
        return user.event_permissions === 3;
    } catch (err) {
        return false;
    }
};

const isVipOrganiser = () => {
    const user = getLoggedInUser();
    try {
        return user.providing_vip_membership;
    } catch (err) {
        return false;
    }
};

const isInOrganisation = () => {
    const user = getLoggedInUser();
    try {
        return user.organization;
    } catch (err) {
        return false;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser, isInOrganisation, isRolePro, isVipOrganiser };
