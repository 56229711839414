import * as React from "react"

function Bell({enable}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 20 20"
      fill="none"
      stroke="#bababa"
    >
      <path
        d="M13.888 11.485l-.555-.497V6.667a3.333 3.333 0 00-6.666 0v4.321l-.555.497a5.822 5.822 0 00-1.704 2.682h11.184a5.823 5.823 0 00-1.704-2.682zM12.5 15.833a2.5 2.5 0 01-5 0h-5a7.482 7.482 0 012.5-5.59V6.667a5 5 0 0110 0v3.576a7.482 7.482 0 012.5 5.59h-5zm-2.5.834a.833.833 0 00.833-.834H9.167a.833.833 0 00.833.834z"
        fill="#bababa"
      />
      <circle
        cx={14}
        cy={3}
        r={2.25}
        fill="#ED3833"
        stroke="#fff"
        strokeWidth={0.5}
        display={!enable && "none"}
      />
    </svg>
  )
}

export default Bell
