import { TOKEN_API } from '../constants/api';
import { setSession } from '../redux/auth/saga';
import { getLoggedInUser } from './authUtils';
import stockAvatar from '../assets/images/custom/stockAvatar.svg';
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then((response) => {
            if (!response.status === 200) throw response.json();
            return response.json();
        })
        .then((json) => json)
        .catch((error) => {
            throw error;
        });
};

/**
 * Fetch data from given url using promises
 * @param {string} url
 * @param {object} options
 */

const fetchPromiseJSON = (url, options = {}) =>
    new Promise((resolve, reject) =>
        fetch(url, options)
            .then((response) => (response.status !== 200 ? reject(response.json()) : resolve(response.json())))
            .catch((err) => reject(err))
    );

const getOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    },
};

/**
 * Updates user cookies
 * to reload cookies store user object in state
 */

const updateUser = (options) =>
    fetchJSON(TOKEN_API, options)
        .then(() =>
            fetchJSON(TOKEN_API, { method: 'GET', headers: options.headers }).then((newUserData) =>
                setSession({ ...newUserData, token: options.headers.Authorization.split(' ')[1] })
            )
        )
        .catch((error) => {
            throw error;
        })
        .catch((error) => {
            throw error;
        });
const getUserAvatar = () => {
    const user = getLoggedInUser();
    return user.avatar ? user.avatar : stockAvatar;
};

export { fetchJSON, fetchPromiseJSON, getOptions, updateUser, getUserAvatar };
